// src/RootRouter.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../widgets/Header/Header.js';
import About from '../pages/About/index.js';
import Contact from '../pages/Contact/index.js';
import MainRouter from './MainRouter.js'; // Updated import
import QuickBtns from '../common/components/quickBtns/QuickBtns.js';
function RootRouter() {
  return (
    <>
      <QuickBtns />
      <Router>
        <Header>
          <Routes>
            <Route exact path="/" element={<About />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about/project/:id" element={<MainRouter />} /> {/* Updated */}
          </Routes>
        </Header>
      </Router>
    </>
  );
}

export default RootRouter;
