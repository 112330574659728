import { quickBtnsData } from '../../../utils/constant/constantx';
import style from './quickBtns.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(style);
export default function QuickBtns() {
  return (
    <div className={cx('QuickBtns')}>
      {quickBtnsData.map((item) => (
        <div key={item.id} data-tooltip={item.datatooltip} type={item.tooltiptype}>
          {item.icon}
        </div>
      ))}
    </div>
  );
}
