import React, { useState } from 'react';

import { ReactComponent as SvgCopy } from '../../assets/svgs/copy.svg';
import { ReactComponent as SvgGitHub } from '../../assets/svgs/github.svg';
import { ReactComponent as SvgGmail } from '../../assets/svgs/gmail.svg';
import classNames from 'classnames/bind';
import Popup from '../../common/components/Modal/Popup.js';
import styles from './styles.module.scss';
import { UseResponsive } from '../../utils/responsive';

const cx = classNames.bind(styles);

function Contact() {
  const [isCopied, setIsCopied] = useState(false); // Fix useState hook
  const { isTablet } = UseResponsive();

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset copy state after 2 seconds
  };

  return (
    <div className={cx('contact-container')}>
      <section id="contact">
        <h1 className={cx('headline1BD')}>CONTACT</h1>
        <div className={cx('contact-item-container')}>
          <div>
            <a href="https://github.com/dkagh012" target="_blank" rel="noopener noreferrer">
              <SvgGitHub />
            </a>
            <span className={cx(isTablet ? 'title2MD' : 'bodyMD')}>USER NAME</span>
            <button
              onClick={() => handleCopy('dkagh012')}
              className={cx(isTablet ? 'headline2SB' : 'title1BD')}
            >
              <SvgCopy />
              dkagh012
            </button>
          </div>
          <div>
            <a href="mailto:qowjdxo00@gmail.com">
              <SvgGmail />
            </a>
            <span className={cx(isTablet ? 'title2MD' : 'bodyMD')}>GMAIL</span>
            <button
              onClick={() => handleCopy('qowjdxo00@gmail.com')}
              className={cx(isTablet ? 'headline2SB' : 'title1BD')}
            >
              <SvgCopy />
              qowjdxo00@gmail.com
            </button>
          </div>
        </div>
        {/* <div className={cx('resume')}>
          <h2 className={cx(isTablet ? 'headline1BD' : 'headline3BD')}> My resume is Here!!</h2>
          <Link
            className={cx(isTablet ? 'headline2SB' : 'title1BD')}
            to="/resume.pdf"
            download="황다희_이력서"
            target="_blank"
          >
            Download
          </Link>
        </div> */}
      </section>
      <Popup
        openModal={isCopied}
        title={'복사되었습니다'}
        onCancel={() => setIsCopied(false)}
        onConfirm={() => setIsCopied(false)}
      />
    </div>
  );
}

export default Contact;

export const path = '/contact';
