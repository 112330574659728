// About/About.js

import React from 'react';
import profile from '../../assets/images/profile.png';
import treeLogo from '../../assets/images/tree/tree_logo.png';
import gift_logo from '../../assets/images/gift/logo.png';
import travelLogo from '../../assets/images/travel/logo.png';
import JCILogo from '../../assets/images/JCI/logo.png';
import U20Logo from '../../assets/images/u20/U20.png';
import ContentsLogo from '../../assets/images/contents/worldHistoryLogo.png';
import WorldLogo from '../../assets/images/world/logo.png';
import MeatLogo from '../../assets/images/meat/logo_bw.png';
import IncludesLogo from '../../assets/images/influde/logo.png';
import {
  SvgCss,
  SvgGit,
  SvgJava,
  SvgJavaScript,
  SvgMysql,
  SvgReact,
  SvgSass,
  SvgSpring,
  Svghtml5,
  Svgjsp,
  SvgVercel,
} from '../../assets/svgs';
import Chip from '../../common/components/molecules/Chip/index';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { UseResponsive } from '../../utils/responsive';
import moment from 'moment-timezone';
const cx = classNames.bind(styles);

function About() {
  const navigate = useNavigate();
  const { isTablet, isDesktop } = UseResponsive();
  let careerDate = moment('2024-04-12').diff(moment('2023-01-02'), 'months');

  const languages = [
    {
      title: '편해요',
      skills: [
        {
          text: () => {
            return (
              <>
                <SvgReact style={{ fill: '#61DAFB' }} />
                React.js
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
        {
          text: () => {
            return (
              <>
                <SvgJavaScript style={{ fill: '#F7DF1E' }} />
                JavaScript(ES6+)
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
        {
          text: () => {
            return (
              <>
                <Svghtml5 style={{ fill: '#E34F26' }} />
                HTML5
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
        {
          text: () => {
            return (
              <>
                <SvgSass style={{ fill: '#CC6699' }} />
                SASS / <SvgCss style={{ fill: '#1572B6' }} />
                CSS3
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
        {
          text: () => {
            return (
              <>
                <SvgGit style={{ fill: '#F05032' }} />
                Git
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
        {
          text: () => {
            return (
              <>
                <SvgMysql style={{ fill: '#4479A1' }} />
                mysql
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
      ],
    },
    {
      title: '어느정도 사용할 수 있어요',
      skills: [
        {
          text: () => {
            return (
              <>
                <SvgVercel className={cx('Vercel')} />
                Vercel
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
        {
          text: () => {
            return (
              <>
                <SvgJava className={cx('java')} />
                JAVA
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
        {
          text: () => {
            return (
              <>
                <Svgjsp className={cx('java')} />
                JSP
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
      ],
    },
    {
      title: '사용해봤어요',
      skills: [
        {
          text: () => {
            return (
              <>
                <SvgReact style={{ fill: '#61DAFB' }} />
                React Native
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
        {
          text: () => {
            return (
              <>
                <SvgSpring style={{ fill: '#6DB33F' }} />
                Spring
              </>
            );
          },
          textColor: '#1b1b1e',
          backgroundColor: '#fffcec',
        },
      ],
    },
  ];

  const tools = [
    {
      title: '편해요',
      skills: [
        {
          text: () => {
            return <>Notion</>;
          },
          type: 'tool',
        },
        {
          text: () => {
            return <>Figma</>;
          },
          type: 'tool',
        },
        {
          text: () => {
            return <>Visual Studio</>;
          },
          type: 'tool',
        },
      ],
    },
    {
      title: '어느정도 사용할 수 있어요',
      skills: [
        {
          text: () => {
            return <>Eclipse</>;
          },
          type: 'tool',
        },
        {
          text: () => {
            return <>SQL Developer</>;
          },
          type: 'tool',
        },
        {
          text: () => {
            return <>Swagger</>;
          },
          type: 'tool',
        },
        {
          text: () => {
            return <>Talend API</>;
          },
          type: 'tool',
        },
      ],
    },
    {
      title: '사용해봤어요',
      skills: [
        {
          text: () => {
            return <>Android Studio</>;
          },
          type: 'tool',
        },
      ],
    },
  ];

  const workingPage = [
    { coverImage: `${IncludesLogo}`, title: '인플루드', url: '/about/project/influde' },
    { coverImage: `${gift_logo}`, title: '고향사랑기부금답례품', url: '/about/project/gift' },
    { coverImage: `${ContentsLogo}`, title: '콘텐츠I', url: '/about/project/contents' },
    { coverImage: `${U20Logo}`, title: '예천아시아 U20 육상경기', url: '/about/project/u20' },
    { coverImage: `${WorldLogo}`, title: '세계유교문화재단', url: '/about/project/world' },
    { coverImage: `${treeLogo}`, title: '분재야생화테마파크', url: '/about/project/tree' },
    { coverImage: `${travelLogo}`, title: '여행이지 클론코딩', url: '/about/project/travel' },
    { coverImage: `${JCILogo}`, title: '안동청년회의소', url: '/about/project/jci' },
    { coverImage: `${MeatLogo}`, title: '늘품한우', url: '/about/project/meat' },
  ];

  return (
    <div className={cx('about-container')}>
      <section className={cx('JungTae-container')} id="JungTae">
        <div className={cx('inner-container')}>
          <div className={cx('left-content')}>
            <img src={profile} alt="JungTae profile" />
          </div>
          <div className={cx('left-content')}>
            <h1 className={cx('headline1BD')} style={{ paddingBottom: '8px' }}>
              배정태
            </h1>
            <h2 className={cx('headline3BD')} style={{ paddingBottom: '8px' }}>
              1998.04.21
            </h2>
            <div className={cx('chip-container')}>
              <Chip
                text={`Frontend-Devloper`}
                textColor="#1b1b1e"
                backgroundColor="#fffcec"
                size="LG"
                className={cx('label', isTablet ? 'captionBD' : 'overlineBD')}
              />
              <a href="https://ko.reactjs.org/">
                <Chip
                  text={`React.js`}
                  textColor="#1b1b1e"
                  backgroundColor="#fffcec"
                  size="LG"
                  className={cx('label', isTablet ? 'captionBD' : 'overlineBD')}
                />
              </a>
            </div>
            <div className={cx(isTablet ? 'title2RG' : 'bodyRG')}>
              안녕하세요. 개발하는것을 즐거워하는 프론트엔드 개발자 배정태 입니다.
              <br />
              2023년 2월에 안동대학교 컴퓨터 공학과를 졸업 후 2023년 1월부터 SI 개발사에서 개발을
              하였습니다.
            </div>
          </div>
        </div>
      </section>
      <section className={cx('skill-container')} id="skill">
        <h1 className={cx('headline1BD')}>SKILLS ABILITY</h1>
        <div className={cx('ability-container', 'ability-container-skill')}>
          <h2 className={cx(isTablet ? 'headline2SB' : 'title1BD')}>Language/Framework/Library</h2>
          {languages?.map((language, index) => {
            return (
              <div key={index}>
                <h3 className={cx(isTablet ? 'headline3MD' : 'title1MD')}>{language?.title}</h3>
                <div className={cx('skill-detail-container')}>
                  {language?.skills?.map((skill, idx) => {
                    return (
                      <Chip
                        key={idx}
                        text={skill?.text}
                        textColor={skill?.textColor}
                        backgroundColor={skill?.backgroundColor}
                        size="LG"
                        className={cx('label', isTablet ? 'bodyBD' : 'captionBD')}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className={cx('ability-container', 'ability-container-tool')}>
          <h2 className={cx(isTablet ? 'headline2SB' : 'title1BD')}>Tools</h2>
          {tools?.map((tool, index) => {
            return (
              <div key={index}>
                <h3 className={cx(isTablet ? 'headline3MD' : 'title1MD')}>{tool?.title}</h3>
                <div className={cx('skill-detail-container')}>
                  {tool?.skills?.map((skill, idx) => {
                    return (
                      <Chip
                        key={idx}
                        text={skill?.text}
                        textColor="#1b1b1e"
                        backgroundColor={
                          skill?.type === 'analytics'
                            ? '#e2fae7'
                            : skill?.type === 'tool'
                            ? '#fff1d5'
                            : '#fad2c8'
                        }
                        size="LG"
                        className={cx('label', isTablet ? 'bodyBD' : 'captionBD')}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className={cx('career-container')} id="career">
        <h1 className={cx('headline1BD')}>
          CAREER(총 {Math.floor(careerDate / 12)}년 {Math.floor(careerDate % 12)}개월)
        </h1>
        <div className={cx('job-desc-wrapper', 'project-wrapper')}>
          <div className={cx('activicy-description', isDesktop ? 'title2RG' : 'bodyRG')}>
            <div className={cx('project-activity')}>
              <div className={cx('items-container')}>
                {workingPage?.map((page, index) => {
                  return (
                    <div key={index} onClick={() => navigate(page?.url)}>
                      <div className={cx('image-container')}>
                        <img src={page?.coverImage} alt={page?.title} />
                      </div>
                      <h3 className={cx(isTablet ? 'title2MD' : 'bodyMD')}>{page?.title}</h3>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={cx('education-container')} id="education">
        <h1 className={cx('headline1BD')}>EDUCATION</h1>
        <div className={cx('education-wrapper')}>
          <div className={cx('school', isTablet ? 'headline2SB' : 'title1BD')}>국립안동대학교</div>
          <div className={cx('school-info')}>
            <h4 className={cx(isTablet ? 'title2MD' : 'bodyMD')}>컴퓨터 과학과</h4>
            <h5 className={cx(isTablet ? 'title2RG' : 'bodyRG')}>2017년 입학 - 2023년 졸업</h5>
          </div>
        </div>
        <div className={cx('education-wrapper')}>
          <div className={cx('school', isTablet ? 'headline2SB' : 'title1BD')}>
            구미 상모고등학교
          </div>
          <div className={cx('school-info')}>
            <h5 className={cx(isTablet ? 'title2RG' : 'bodyRG')}>2014년 입학 - 2017년 졸업</h5>
          </div>
        </div>
      </section>
      <section className={cx('certificate-container')} id="certificate">
        <h1 className={cx('headline1BD')}>CERTIFICATE</h1>
        <div className={cx('certificate-wrapper')}>
          <div className={cx('certificate', isTablet ? 'headline2SB' : 'title1BD')}>
            정보처리기사 필기
          </div>
          <div className={cx('certificate-info')}>
            <h4 className={cx(isTablet ? 'title2MD' : 'bodyMD')}>실기 준비중</h4>
          </div>
        </div>
      </section>
      <section className={cx('Contact-container')} id="Contact">
        <h1 className={cx('headline1BD')}>CONTACT</h1>
        <div className={cx('Contact-wrapper')}>
          <div className={cx('Contact', isTablet ? 'headline2SB' : 'title1BD')}>GitHub</div>
          <div className={cx('Contact-info')}>
            <h4 className={cx(isTablet ? 'title2MD' : 'bodyMD')}>https://github.com/dkagh012</h4>
          </div>
        </div>
        <div className={cx('Contact-wrapper')}>
          <div className={cx('Contact', isTablet ? 'headline2SB' : 'title1BD')}>Mail</div>
          <div className={cx('Contact-info')}>
            <h4 className={cx(isTablet ? 'title2MD' : 'bodyMD')}>qowjdxo00@gmail.com</h4>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
export const path = ['/', '/about']; // path를 배열로 설정
