import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { UseResponsive } from '../../utils/responsive';
import Main from '../../assets/images/world/world_main.JPG';
import DeskTop from '../../assets/images/world/world_DeskTop.JPG';
import Tablet from '../../assets/images/world/world_Tablet.JPG';
import Mobile from '../../assets/images/world/world_Mobile.JPG';

import Intro from '../../assets/images/world/Intro.JPG';
import Festival from '../../assets/images/world/Festival.JPG';
import Notice from '../../assets/images/world/Notice.JPG';
import projects from './projects.json';
import { FaChevronCircleDown } from 'react-icons/fa';
const cx = classNames.bind(styles);

const lists = [
  {
    images: [{ url: Mobile }, { url: Tablet }, { url: DeskTop }],
    category: [{ url: Intro }, { url: Festival }, { url: Notice }],
  },
];

function World() {
  const { id } = useParams(); // URL에서 id를 가져옴
  const { isTablet } = UseResponsive();
  const [project, setProject] = useState(null);
  const [listOpenStates, setListOpenStates] = useState({});
  const [responseOpen, setResponseOpen] = useState(false);

  const handleListOpen = (idx) => {
    setListOpenStates((prev) => ({
      ...prev,
      [idx]: !prev[idx],
    }));
  };

  const handleResponseOpen = () => {
    setResponseOpen((prev) => !prev);
  };

  useEffect(() => {
    const selectedProject = projects.find((proj) => proj.id.toLowerCase() === id.toLowerCase());
    setProject(selectedProject);
  }, [id]);

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className={cx('project-response-container')}>
      <span className={cx(isTablet ? 'bodyRG' : 'captionRG')}>홈페이지 프로젝트</span>
      <h1 className={cx('headline1BD')}>{project.title}</h1>
      <img className={cx('code-image')} src={Main} alt="Main" />
      <div className={cx('information-container', 'container')}>
        <div>
          <div className={cx('info-title', 'title2BD')}>SKILLS</div>
          <div className={cx('info-skill')}>
            {project.skills.map((skill) => (
              <div
                key={skill.text}
                className={cx('label', isTablet ? 'bodyBD' : 'captionBD')}
                style={{ backgroundColor: skill.color, color: skill.textColor }}
              >
                {skill.text}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className={cx('info-title', 'title2BD')}>TOOLS</div>
          <div className={cx('info-skill')}>
            {project.tools.map((tool) => (
              <div
                key={tool.text}
                className={cx('label', isTablet ? 'bodyBD' : 'captionBD')}
                style={{ backgroundColor: tool.color, color: tool.textColor }}
              >
                {tool.text}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className={cx('info-title', 'title2BD')}>진행기간</div>
          <div className={cx('info-duration', 'title2RG')}>
            <div className={cx('info-duration', isTablet ? 'title2RG' : 'bodyRG')}>
              {project.duration}
            </div>
          </div>
        </div>
        <div>
          <div className={cx('info-title', 'title2BD')}>개발인원</div>
          <div className={cx('info-member', 'title2RG')}>
            <div className={cx('info-member', isTablet ? 'title2RG' : 'bodyRG')}>
              {project.peoples.total}명 (개발 {project.peoples.developer}명)
            </div>
          </div>
        </div>
        <div>
          <div className={cx('info-title', 'title2BD')}>역할</div>
          <div className={cx('info-role')}>
            {project.roles.map((role) => (
              <div
                key={role.text}
                className={cx('label', isTablet ? 'bodyBD' : 'captionBD')}
                style={{ backgroundColor: role.color }}
              >
                {role.text}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className={cx('info-title', 'title2BD')}>기여도</div>
          <div className={cx('info-contribution', 'title2RG')}>
            <div className={cx('info-contribution', isTablet ? 'title2RG' : 'bodyRG')}>
              {project.contribution}
            </div>
          </div>
        </div>
        <div>
          <div className={cx('info-title', 'title2BD')}>카테고리</div>
          <div className={cx('info-category')}>
            <div className={cx('label', isTablet ? 'bodyBD' : 'captionBD')}>{project.category}</div>
          </div>
        </div>
        <div>
          <div className={cx('info-title', 'title2BD')}>링크</div>
          <div className={cx('info-category')}>
            <a href={project.url} className={cx('link', isTablet ? 'title2RG' : 'bodyRG')}>
              {project.url}
            </a>
          </div>
        </div>
      </div>

      <div className={cx('response-lists-container')}>
        <div className={cx('response-container')}>
          <strong>주요 기능 및 특징</strong>
          <ul className={cx('title2RG', 'bodyRG')}>
            {project.description.map((description, idx) => (
              <li key={idx}>{description}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={cx('category_container')}>
        <div onClick={handleResponseOpen} className={cx('ListItem')}>
          <h2 className={cx(isTablet ? 'headline3BD' : 'title1BD', 'ListTitle')}>
            반응형작업 <FaChevronCircleDown className={cx('ListDownIcon')} />
          </h2>
          {responseOpen && (
            <div className={cx('response-images-container')}>
              {lists.map((list) =>
                list.images.map((image, idx) => (
                  <div key={idx} className={cx('image-container')}>
                    <img src={image.url} alt={`response_image_${idx}`} />
                  </div>
                )),
              )}
            </div>
          )}
        </div>
        {project.Web_category.map((item, idx) => (
          <div key={idx} className={cx('ListItem')}>
            <h2
              onClick={() => handleListOpen(idx)}
              className={cx(isTablet ? 'headline3BD' : 'title1BD', 'ListTitle')}
            >
              {item.title}
              <FaChevronCircleDown className={cx('ListDownIcon')} />
            </h2>
            {listOpenStates[idx] && (
              <>
                <div className={cx('category_images')}>
                  {lists[0].category[idx] && (
                    <img src={lists[0].category[idx].url} alt={`category_image_${idx}`} />
                  )}
                </div>
                <ul className={cx('title2RG', 'bodyRG')}>
                  {item.text.map((textItem, textIdx) => (
                    <li key={textIdx}>{textItem}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default World;
