import GoTopBtn from '../../common/components/button/GotoTopBtn';
import MailSend from '../../common/components/button/MailSendBtn';

export const quickBtnsData = [
  {
    id: 1,
    datatooltip: '5064664@naver.com',
    tooltiptype: 'left',
    icon: <MailSend name="이메일 주소 복사" />,
  },
  {
    id: 2,
    datatooltip: '위로가기',
    tooltiptype: 'left',
    icon: <GoTopBtn name="위로가기" />,
  },
];
