import { CiMail } from 'react-icons/ci';
import style from './Button.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(style);

function MailSentBtn() {
  const goToSite = () => {
    const mailUrl = 'qowjdxo00@gmail.com';
    navigator.clipboard.writeText(mailUrl).then(() => {
      alert('이메일 주소가 복사되었습니다!');
    });
  };

  return (
    <button className={cx('MailSend', 'Button')} onClick={goToSite}>
      <CiMail width={'20'} height={'20'} />
    </button>
  );
}

export default MailSentBtn;
