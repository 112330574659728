// src/router/MainRouter.js
import React from 'react';
import { useParams } from 'react-router-dom';
import Influde from '../pages/About/Influde';
import Gift from '../pages/About/gift';
import Contents from '../pages/About/contents';
import U20 from '../pages/About/u20';
import World from '../pages/About/world';
import Travel from '../pages/About/travel';
import Jci from '../pages/About/jci';
import Meat from '../pages/About/meat';
import Tree from '../pages/About/tree'; // Remove .js if you're using ES6 module imports
const MainRouterComponents = {
  influde: Influde,
  gift: Gift,
  contents: Contents,
  u20: U20,
  world: World,
  travel: Travel,
  jci: Jci,
  tree: Tree,
  meat: Meat,
};

function MainRouter() {
  const { id } = useParams();
  const MainRouterComponent = MainRouterComponents[id.toLowerCase()];

  return MainRouterComponent ? <MainRouterComponent /> : <div>MainRouter not found</div>;
}

export default MainRouter;
