import { IoIosArrowUp } from 'react-icons/io';
import style from './Button.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(style);
function GoTopBtn() {
  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <button className={cx('GotoTopBtn', 'Button')} onClick={goToTop}>
      <IoIosArrowUp width={'20'} height={'20'} />
    </button>
  );
}

export default GoTopBtn;
